import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import jwt_decode from "jwt-decode";
//import { PaperClipIcon } from "@heroicons/react/20/solid";
//import TimezoneSelect from "react-timezone-select";

export default class Profile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirect: null,
			userReady: false,
			currentUser: { username: "" },
			accessToken: null,
			refreshToken: null,
			expiresAt: null,
			language: "en-US",
			autorities: null,
			selectedTimezone: "",
		};
	}

	componentDidMount() {
		const result = AuthService.getCurrentUser();

		if (!result) this.setState({ redirect: "/home" });
		const { accessToken, refreshToken } = result;

		const decodedToken = jwt_decode(accessToken);

		this.setState({
			currentUser: decodedToken,
			userReady: true,
			accessToken: accessToken,
			refreshToken: refreshToken,
			expiresAt: decodedToken.exp,
			language: decodedToken.cultureUserInfo,
			autorities: decodedToken.customerCompanyName,
			selectedTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		});
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		const { currentUser, selectedTimezone, language, autorities } = this.state;

		return (
			<div>
				<div className="px-4 sm:px-0">
					<h3 className="text-base font-semibold leading-7 text-gray-900">
						Applicant Information
					</h3>
					<p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
						Personal details and application.
					</p>
				</div>
				{this.state.userReady ? (
					<div className="mt-6 border-t border-gray-100">
						<dl className="divide-y divide-gray-100">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Full name
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{currentUser.unique_name}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Email address
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{currentUser.email}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Language
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{language}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Company
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{autorities}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Type
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{currentUser.role}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Time Zone
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{selectedTimezone}
								</dd>
							</div>
						</dl>
					</div>
				) : null}
			</div>
		);
	}
}
