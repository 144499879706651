import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { useNavigate } from "react-router-dom";

import AuthService from "../services/auth.service";

const required = (value) => {
	if (!value) {
		return (
			<div className="mt-2 text-sm text-red-600" role="alert">
				This field is required.
			</div>
		);
	}
};

const email = (value) => {
	if (!isEmail(value)) {
		return (
			<div className="mt-2 text-sm text-red-600" role="alert">
				Please use a valid email address.
			</div>
		);
	}
};

const vfullname = (value) => {
	if (value.length < 3 || value.length > 20) {
		return (
			<div className="mt-2 text-sm text-red-600" role="alert">
				The fullname must be between 3 and 20 characters.
			</div>
		);
	}
};

const vcompany = (value) => {
	if (value.length < 3 || value.length > 20) {
		return (
			<div className="mt-2 text-sm text-red-600" role="alert">
				The company must be between 3 and 20 characters.
			</div>
		);
	}
};

const vpassword = (value) => {
	if (value.length < 6 || value.length > 40) {
		return (
			<div className="mt-2 text-sm text-red-600" role="alert">
				The password must be between 6 and 40 characters.
			</div>
		);
	}
};

export default class Register extends Component {
	constructor(props) {
		super(props);
		this.handleRegister = this.handleRegister.bind(this);
		this.onChangeFullname = this.onChangeFullname.bind(this);
		this.onChangeCompany = this.onChangeCompany.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);

		this.state = {
			fullname: "",
			company: "",
			email: "",
			password: "",
			successful: false,
			message: "",
		};
	}

	onChangeFullname(e) {
		this.setState({
			fullname: e.target.value,
		});
	}

	onChangeCompany(e) {
		this.setState({
			company: e.target.value,
		});
	}

	onChangeEmail(e) {
		this.setState({
			email: e.target.value,
		});
	}

	onChangePassword(e) {
		this.setState({
			password: e.target.value,
		});
	}

	handleRegister(e) {
		e.preventDefault();

		this.setState({
			message: "",
			successful: false,
		});

		this.form.validateAll();

		if (this.checkBtn.context._errors.length === 0) {
			AuthService.register(
				this.state.fullname,
				this.state.company,
				this.state.email,
				this.state.password,
				this.state.password
			).then(
				(response) => {
					this.setState({
						message: response.data.message,
						successful: true,
					});
					useNavigate("/login");
				},
				(error) => {
					const resMessage =
						(error.response && error.response.data && error.response.data[0]) ||
						error.message ||
						error.toString();

					this.setState({
						successful: false,
						message: resMessage,
					});
				}
			);
		}
	}

	render() {
		return (
			<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-10 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<h2 className="mt-10 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
						Application Form
					</h2>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<Form
						className="space-y-6"
						onSubmit={this.handleRegister}
						ref={(c) => {
							this.form = c;
						}}
					>
						{!this.state.successful && (
							<>
								<label
									htmlFor="fullname"
									className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>
									First and Last Name
								</label>
								<div className="mt-2">
									<Input
										type="text"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										name="fullname"
										value={this.state.fullname}
										onChange={this.onChangeFullname}
										validations={[required, vfullname]}
									/>
								</div>

								<label
									htmlFor="company"
									className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>
									Company Name
								</label>
								<div className="mt-2">
									<Input
										type="text"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										name="company"
										value={this.state.company}
										onChange={this.onChangeCompany}
										validations={[required, vcompany]}
									/>
								</div>

								<label
									htmlFor="email"
									className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>
									Email Address
								</label>
								<div className="mt-2">
									<Input
										type="email"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										name="email"
										autoComplete="email"
										value={this.state.email}
										onChange={this.onChangeEmail}
										validations={[required, email]}
									/>
								</div>
								<label
									htmlFor="password"
									className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>
									Password
								</label>
								<div className="mt-2">
									<Input
										type="password"
										className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										name="password"
										value={this.state.password}
										onChange={this.onChangePassword}
										validations={[required, vpassword]}
									/>
								</div>

								<button
									type="submit"
									disabled={this.state.loading}
									className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 px-5 py-2.5 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Create an account
									{this.state.loading && (
										<span
											className="spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true"
										></span>
									)}
								</button>
							</>
						)}

						{this.state.message && (
							<div className="form-group">
								<div
									className={
										this.state.successful
											? "mt-2 text-sm text-green-600"
											: "mt-2 text-sm text-red-600"
									}
									role="alert"
								>
									<li>{this.state.message}</li>
								</div>
							</div>
						)}
						<CheckButton
							style={{ display: "none" }}
							ref={(c) => {
								this.checkBtn = c;
							}}
						/>
					</Form>
				</div>
			</div>
		);
	}
}
