import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dialog, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import AuthService from "../services/auth.service";

import Banner from "./home-banner.component";
import AuthVerify from "../common/auth-verify";
import EventBus from "../common/EventBus";

export default class Header extends Component {
	constructor(props) {
		super(props);
		this.logOut = this.logOut.bind(this);
		this.onCloseMenu = this.onCloseMenu.bind(this);

		this.state = {
			showHomeBoard: false,
			showModeratorBoard: false,
			showAdminBoard: false,
			currentUser: undefined,
			mobileMenuOpen: false,
		};
	}

	componentDidMount() {
		const user = AuthService.getCurrentUser();

		if (user) {
			this.setState({
				currentUser: user,
				//showHomeBoard: user.roles.includes("ROLE_GUEST"),
				//showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
				//showAdminBoard: user.roles.includes("ROLE_ADMIN"),
			});
		}

		EventBus.on("logout", () => {
			this.logOut();
		});
	}

	componentWillUnmount() {
		EventBus.remove("logout");
	}

	onCloseMenu = () => {
		this.setState({
			mobileMenuOpen: false,
		});
	};

	logOut = () => {
		AuthService.logout();
		this.setState({
			showHomeBoard: false,
			showModeratorBoard: false,
			showAdminBoard: false,
			mobileMenuOpen: false,
			currentUser: undefined,
		});
	};

	render() {
		const { currentUser, mobileMenuOpen, showModeratorBoard, showAdminBoard } =
			this.state;

		return (
			<div>
				{currentUser ? "" : <Banner />}
				<header className="bg-white dark:bg-black">
					<nav
						className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
						aria-label="Global"
					>
						<div className="flex lg:flex-1">
							<Link
								to={"/"}
								className="-m-1.5 p-1.5 h5 py-4 px-2 text-gray-900 hover:text-gray-600 transition duration-300"
							>
								<img
									width="250"
									height="auto"
									alt="spacecore"
									src={require("../borders-spacecore-black.png")}
								></img>
							</Link>
						</div>
						<div className="flex lg:hidden">
							<button
								type="button"
								className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
								onClick={() => this.setState({ mobileMenuOpen: true })}
							>
								<span className="sr-only">Open main menu</span>
								<Bars3Icon className="h-6 w-6" aria-hidden="true" />
							</button>
						</div>
						<div className="hidden lg:flex lg:flex-1 lg:justify-end">
							{currentUser ? (
								<div>
									<Popover.Group className="flex md:flex md:flex-grow space-x-1">
										{showModeratorBoard && (
											<Link
												to={"/mod"}
												className="py-4 px-2 text-gray-500 hover:text-gray-600 transition duration-300"
											>
												Moderator Board
											</Link>
										)}
										{showAdminBoard && (
											<Link
												to={"/admin"}
												className="py-4 px-2 text-gray-500 hover:text-gray-600 transition duration-300"
											>
												Admin Board
											</Link>
										)}
										{currentUser && (
											<Link
												to={"/profile"}
												className="py-4 px-2 text-gray-500 hover:text-gray-600 transition duration-300"
											>
												Profile
											</Link>
										)}
										{currentUser && (
											<Link
												to={"/login"}
												onClick={this.logOut}
												className="py-4 px-2 text-gray-700 font-semibold hover:text-gray-600 transition duration-300"
											>
												LogOut <span aria-hidden="true">&rarr;</span>
											</Link>
										)}
									</Popover.Group>
								</div>
							) : (
								<Link
									to={"/login"}
									className="py-4 px-2 text-gray-700 font-semibold hover:text-gray-600 transition duration-300"
								>
									Sign in <span aria-hidden="true">&rarr;</span>
								</Link>
							)}
						</div>
					</nav>
					<Dialog
						as="div"
						className="lg:hidden"
						open={mobileMenuOpen}
						onClose={this.onCloseMenu}
					>
						<div className="fixed inset-0 z-10" />
						<Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
							<div className="flex items-center justify-between">
								<Link
									to={"/"}
									className="-m-1.5 p-1.5 h5 py-4 px-2 text-gray-900 hover:text-gray-600 transition duration-300"
									onClick={this.onCloseMenu}
								>
									<span className="sr-only">Menu</span>
									Menu
								</Link>
								<button
									type="button"
									className="-m-2.5 rounded-md p-2.5 hover:text-gray-600 transition duration-300"
									onClick={this.onCloseMenu}
								>
									<span className="sr-only">Close menu</span>
									<XMarkIcon className="h-6 w-6" aria-hidden="true" />
								</button>
							</div>
							<div className="mt-6 flow-root">
								<div className="-my-6 divide-y divide-gray-500/10">
									<div className="py-6">
										{currentUser ? (
											<div>
												<Popover.Group className="lg:flex lg:gap-x-3">
													{showModeratorBoard && (
														<Link
															to={"/mod"}
															onClick={this.onCloseMenu}
															className="-mx-3 block rounded-lg px-3 py-2.5 text-base leading-7 text-gray-900 hover:text-gray-600 transition duration-300"
														>
															Moderator Board
														</Link>
													)}
													{showAdminBoard && (
														<Link
															to={"/admin"}
															onClick={this.onCloseMenu}
															className="-mx-3 block rounded-lg px-3 py-2.5 text-base leading-7 text-gray-900 hover:text-gray-600 transition duration-300"
														>
															Admin Board
														</Link>
													)}
													{currentUser && (
														<Link
															to={"/profile"}
															onClick={this.onCloseMenu}
															className="-mx-3 block rounded-lg px-3 py-2.5 text-base leading-7 text-gray-900 hover:text-gray-600 transition duration-300"
														>
															Profile
														</Link>
													)}
												</Popover.Group>
												<Link
													to={"/login"}
													onClick={this.logOut}
													className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:text-gray-600 transition duration-300"
												>
													LogOut
												</Link>
											</div>
										) : (
											<Link
												to={"/login"}
												onClick={this.onCloseMenu}
												className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:text-gray-600 transition duration-300"
											>
												Sign in <span aria-hidden="true">&rarr;</span>
											</Link>
										)}
									</div>
								</div>
							</div>
						</Dialog.Panel>
					</Dialog>
				</header>

				<AuthVerify logOut={this.logOut} />
			</div>
		);
	}
}
