import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { useImage } from "react-image";

import logo from "../logo.png";

function ImageComponent() {
	const { src } = useImage({
		srcList: logo,
	});

	return <img src={src} className="h-4 mr-2 inline" alt="alt" />;
}

export default function Banner() {
	return (
		<div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
			<div className="flex flex-wrap items-center gap-x-4 gap-y-2">
				<p className="text-sm leading-6 text-gray-900">
					<strong className="font-semibold">Partners</strong>
					<svg
						viewBox="0 0 2 2"
						className="mx-2 inline h-0.5 w-0.5 fill-current"
						aria-hidden="true"
					>
						<circle cx={1} cy={1} r={1} />
					</svg>
					Join as a partner to drive your business growth.
				</p>
				<Link
					to={"/register"}
					className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
				>
					Become a Partner <span aria-hidden="true">&rarr;</span>
				</Link>
			</div>
			<div className="flex flex-1 justify-end">
				<button
					type="button"
					className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
					onClick={() =>
						(document.querySelector(".relative").style.display = "none")
					}
				>
					<span className="sr-only">Dismiss</span>
					<XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
				</button>
			</div>
		</div>
	);
}
